
import $app from '@/plugins/modules'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IPatient, IPatientPhoto } from '../types';

@Component
export default class CustomerPhotosTab extends Vue {
  @Prop({ default: () => { } })
  readonly patient: IPatient | undefined;

  historyVisible = false;
  historyType = -1;
  historyTypeName = '';

  imageShow = false;
  imageData: IPatientPhoto | null = null;

  get photos(): Array<IPatientPhoto> {
    return this.patient && this.patient.photos
      ? this.patient.photos.filter(p => !p.archived)
      : [];
  }

  get historyPhotos(): Array<IPatientPhoto> {
    return this.patient && this.patient.photos
      ? this.patient.photos.filter(p => p.id && p.archived && p.type === this.historyType)
      : [];
  }

  async archive(photo: IPatientPhoto) {
    if (this.patient) {
      photo.processing = true;
      try {
        let archive = $app.clone(photo);
        archive.archived = true;
        archive = await $app.patch('/api/patients/' + this.patient.patientId + '/photos/' + photo.id, archive);
        this.patient.photos.push(archive)
        photo.id = null;
      } catch (err) {
        $app.pushError(err);
      }
      photo.processing = false;
    }
  }

  async remove(photo: IPatientPhoto) {
    if (this.patient) {
      photo.processing = true;
      try {
        await $app.delete('/api/patients/' + this.patient.patientId + '/photos/' + photo.id);
        this.patient.photos = this.patient.photos.filter(p => p.id !== photo.id)
      } catch (err) {
        $app.pushError(err);
      }
      photo.processing = false;
    }
  }

  async restore(photo: IPatientPhoto) {
    if (this.patient) {
      photo.processing = true;
      try {
        const current = this.patient.photos.find(p => p.id !== photo.id && !p.archived && p.type === photo.type)
        if (current) {
          const item = $app.clone(current);
          item.archived = true;
          await $app.patch('/api/patients/' + this.patient.patientId + '/photos/' + item.id, item);
          current.archived = true;
        }

        const item = $app.clone(photo);
        item.archived = false;
        await $app.patch('/api/patients/' + this.patient.patientId + '/photos/' + item.id, item);
        photo.archived = false;
      } catch (err) {
        $app.pushError(err);
      }
      photo.processing = false;
    }
  }

  async history(photo: IPatientPhoto) {
    this.historyType = photo.type;
    this.historyTypeName = photo.typeName;
    this.historyVisible = true;
  }

  open(photo: IPatientPhoto) {
    this.imageData = photo;
    this.imageShow = true;
  }
}
